import { useEffect, useState } from "react";
import { useNotificationContext } from "cerulean";
import { useLocalization } from "@fluent/react";
import ApiHttp from "byzantine/src/ApiHttp";
import DualApprovalRequest from "byzantine/src/DualApprovalRequest";
import Pagination from "byzantine/src/Pagination";
import Loading from "../Loading";
import PageNavigation from "../page_navigation/PageNavigation";
import DualApprovalPendingTable from "./DualApprovalPendingTable";
import { SelectedDualApprovalsMapperType } from "./DualApprovalContainer";

type DualApprovalPendingContainerType = {
  updateTotal: (total: number) => void;
  selectedDualApprovals: SelectedDualApprovalsMapperType;
  setSelectedDualApprovals: React.Dispatch<
    React.SetStateAction<SelectedDualApprovalsMapperType>
  >;
};

export type APIPaginatedListType = {
  loading: boolean;
  // TODO: update with ACH type
  items: API.WireDualApproval[];
  pagination: Pagination;
};

const PAGE_SIZE = 10;
const APPROVE_ACTION = "approve";
const REJECT_ACTION = "reject";

export const usePaginatedApprovals = (
  endpoint: string,
  keyname: string,
  page: number,
  pageSize: number,
) => {
  const [dualApprovals, setDualApprovals] = useState<DualApprovalRequest[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchPaginatedDualApprovals = async () => {
    setLoading(true);
    try {
      const response = await ApiHttp.fetch(
        endpoint,
        {},
        { page, per_page: pageSize },
      );
      const deserializedApprovals = response[keyname].map(
        // TODO: update with ACH type
        (request: API.WireDualApproval) =>
          DualApprovalRequest.deserialize(request),
      );
      setTotal(response.meta.total);
      setDualApprovals(deserializedApprovals);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchPaginatedDualApprovals,
    dualApprovals,
    setDualApprovals,
    total,
    loading,
  };
};

const useApprovalActions = (
  selectedDualApprovals: DualApprovalPendingContainerType["selectedDualApprovals"],
  setSelectedDualApprovals: DualApprovalPendingContainerType["setSelectedDualApprovals"],
) => {
  const [loading, setLoading] = useState(false);

  const processApprovals = async (
    action: typeof APPROVE_ACTION | typeof REJECT_ACTION,
  ) => {
    const promises = Object.values(selectedDualApprovals).map(
      async (approval) => {
        if (action === "approve") {
          return approval.approve();
        } else {
          return approval.reject();
        }
      },
    );

    const results = await Promise.allSettled(promises);

    // update mapper
    const updatedApprovals = { ...selectedDualApprovals };
    results.forEach((result) => {
      if (result.status === "fulfilled") {
        const approvedOrRejectedApproval = result.value.approval_request;
        const { uuid } = approvedOrRejectedApproval;
        delete updatedApprovals[uuid];
      }
    });

    setSelectedDualApprovals(updatedApprovals);
    return results;
  };

  const handleAction = async (
    action: typeof APPROVE_ACTION | typeof REJECT_ACTION,
  ) => {
    setLoading(true);
    const results = await processApprovals(action);
    setLoading(false);
    return results;
  };

  const handleToggleApproval = (dualApproval: DualApprovalRequest) => {
    const updatedApprovals = { ...selectedDualApprovals };
    const { uuid } = dualApproval;
    if (updatedApprovals[uuid]) {
      delete updatedApprovals[uuid];
    } else {
      updatedApprovals[uuid] = dualApproval;
    }
    setSelectedDualApprovals(updatedApprovals);
  };

  const selectAllApprovals = (approvals: DualApprovalRequest[]) => {
    const updatedApprovals = { ...selectedDualApprovals };
    approvals.forEach((approval) => {
      updatedApprovals[approval.uuid] = approval;
    });
    setSelectedDualApprovals(updatedApprovals);
  };

  const deselectAllApprovals = () => {
    setSelectedDualApprovals({});
  };

  return {
    handleApprove: () => handleAction(APPROVE_ACTION),
    handleReject: () => handleAction(REJECT_ACTION),
    handleToggleApproval,
    selectAllApprovals,
    deselectAllApprovals,
    loading,
  };
};

const DualApprovalPendingContainer = ({
  updateTotal,
  selectedDualApprovals,
  setSelectedDualApprovals,
}: DualApprovalPendingContainerType) => {
  const { l10n } = useLocalization();
  const [page, setPage] = useState(1);
  const { sendNotification } = useNotificationContext();

  const {
    fetchPaginatedDualApprovals,
    dualApprovals,
    setDualApprovals,
    total,
    loading,
  } = usePaginatedApprovals(
    "approval_requests",
    "approval_requests",
    page,
    PAGE_SIZE,
  );

  // wrapper to show error notification
  const handleFetchPaginatedApprovals = () => {
    fetchPaginatedDualApprovals().then((result) => {
      if (!result.success) {
        sendNotification({
          type: "negative",
          text: l10n.getString("error-generic"),
        });
      }
    });
  };

  const {
    handleApprove,
    handleReject,
    handleToggleApproval,
    selectAllApprovals,
    deselectAllApprovals,
    loading: actionLoading,
  } = useApprovalActions(selectedDualApprovals, setSelectedDualApprovals);

  useEffect(() => {
    handleFetchPaginatedApprovals();
  }, [page]);

  useEffect(() => {
    updateTotal(total);
  }, [total, updateTotal]);

  if (loading || actionLoading) {
    return <Loading />;
  }

  if (dualApprovals.length === 0) {
    return (
      <div className="empty-message">
        {l10n.getString("heading-no-approval-history")}
      </div>
    );
  }

  const handleClickAction = async (
    action: typeof APPROVE_ACTION | typeof REJECT_ACTION,
  ) => {
    let results;
    if (action === APPROVE_ACTION) {
      results = await handleApprove();
    } else {
      results = await handleReject();
    }

    // show notification based on results
    if (results?.some((result) => result.status === "rejected")) {
      sendNotification({
        type: "negative",
        text: l10n.getString("error-generic"),
      });
    } else {
      const numSelected = results?.filter(
        (result) => result.status === "fulfilled",
      ).length;
      const actionKey =
        action === APPROVE_ACTION
          ? "approval-notif-items-approved"
          : "approval-notif-items-rejected";
      if (numSelected) {
        sendNotification({
          type: "success",
          text: l10n.getString(actionKey, { numSelected }),
        });
      }
    }

    handleFetchPaginatedApprovals();
  };

  const handleClickApprove = () => handleClickAction(APPROVE_ACTION);
  const handleClickReject = () => handleClickAction(REJECT_ACTION);

  const pagination = new Pagination({
    total,
    page,
    pageSize: PAGE_SIZE,
    navigatePage: setPage,
  });

  return (
    <>
      <DualApprovalPendingTable
        setDualApprovals={setDualApprovals}
        dualApprovalRequests={dualApprovals}
        selectedDualApprovals={selectedDualApprovals}
        handleToggleCheckbox={handleToggleApproval}
        handleToggleCheckboxHeader={(isSelected) =>
          isSelected
            ? deselectAllApprovals()
            : selectAllApprovals(dualApprovals)
        }
      />
      <PageNavigation pagination={pagination} marginClasses="margin--all--l" />
      {/* TODO: remove when implementing snackbar */}
      <button onClick={handleClickReject}>reject</button>
      <button onClick={handleClickApprove}>approve</button>
    </>
  );
};

export default DualApprovalPendingContainer;
