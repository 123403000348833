import { useState } from "react";
import { orderBy } from "lodash";
import { useLocalization } from "@fluent/react";
import { CheckSquare, Square } from "react-feather"; // eslint-disable-line
import { Table } from "semantic-ui-react";
import Filters from "byzantine/src/filters";
import DualApprovalRequest from "byzantine/src/DualApprovalRequest";
import { SelectedDualApprovalsMapperType } from "./DualApprovalContainer";
import styles from "./DualApproval.module.scss";

type DualApprovalRequestCheckboxType = {
  isSelected: boolean;
  handleToggleCheckbox: () => void;
};

type DualApprovalPendingTableType = {
  dualApprovalRequests: DualApprovalRequest[];
  selectedDualApprovals: SelectedDualApprovalsMapperType;
  handleToggleCheckbox: (dualApproval: DualApprovalRequest) => void;
  handleToggleCheckboxHeader: (isSelected: boolean) => void;
  setDualApprovals: React.Dispatch<React.SetStateAction<DualApprovalRequest[]>>;
};

type DualApprovalTableSortConfigType = {
  column: "date" | "to" | "submittedBy" | "amount" | null;
  direction: "asc" | "desc" | null;
};

const DualApprovalCheckbox = ({
  isSelected,
  handleToggleCheckbox,
}: DualApprovalRequestCheckboxType) => {
  const Checkbox = isSelected ? CheckSquare : Square;

  return (
    <div style={{ width: "16px" }}>
      <Checkbox
        onClick={handleToggleCheckbox}
        size="16px"
        className={isSelected ? "checkbox checked" : "checkbox"}
      />
    </div>
  );
};

const DualApprovalPendingTable = ({
  dualApprovalRequests,
  setDualApprovals,
  selectedDualApprovals,
  handleToggleCheckboxHeader,
  handleToggleCheckbox,
}: DualApprovalPendingTableType) => {
  const { l10n } = useLocalization();
  const [sortConfig, setSortConfig] = useState<DualApprovalTableSortConfigType>(
    { column: "date", direction: "desc" },
  );

  if (!dualApprovalRequests.length) {
    return (
      <div className="emptyState">
        {l10n.getString("heading-no-pending-approvals")}
      </div>
    );
  }

  const sortApprovals = (column: DualApprovalTableSortConfigType["column"]) => {
    const isSameColumn = sortConfig.column === column;

    // on first sort (aka on first click), sort should be asc
    const direction =
      isSameColumn && sortConfig.direction === "asc" ? "desc" : "asc";

    const getSortedData = () => {
      let sortedData: DualApprovalRequest[] = [];
      if (column === "date") {
        sortedData = orderBy(dualApprovalRequests, ["created_at"], [direction]);
      } else if (column === "to") {
        sortedData = orderBy(dualApprovalRequests, ["action.to"], [direction]);
      } else if (column === "submittedBy") {
        sortedData = orderBy(
          dualApprovalRequests,
          ["requester.first_name"],
          [direction],
        );
      } else if (column === "amount") {
        sortedData = orderBy(
          dualApprovalRequests,
          ["action.amount"],
          [direction],
        );
      }

      return sortedData;
    };

    const data = getSortedData();
    setSortConfig({ column, direction });
    setDualApprovals(data);
  };

  const isCheckboxHeaderSelected = dualApprovalRequests.every(
    (approvalRequest) => selectedDualApprovals?.[approvalRequest.uuid],
  );

  const ArrowIcon = ({
    column,
  }: {
    column: DualApprovalTableSortConfigType["column"];
  }) => {
    const { direction, column: columnConfig } = sortConfig;
    const isSameColumn = columnConfig === column;

    const getIconClass = () => {
      if (!isSameColumn || direction === "asc") {
        return "narmi-icon-arrow-up";
      }
      if (direction === "desc") {
        return "narmi-icon-arrow-down";
      }
      return "narmi-icon-arrow-up";
    };

    return (
      <span className={`fontSize--xs margin--left--xxs ${getIconClass()}`} />
    );
  };

  return (
    <div className="selectable-rows">
      <Table selectable className={styles.table}>
        <Table.Header className="mobile-hidden">
          <Table.Row>
            <Table.HeaderCell collapsing>
              <DualApprovalCheckbox
                isSelected={isCheckboxHeaderSelected}
                handleToggleCheckbox={() =>
                  handleToggleCheckboxHeader(isCheckboxHeaderSelected)
                }
              />
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => sortApprovals("date")}>
              {l10n.getString("th-scheduled-date")}
              <ArrowIcon column="date" />
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => sortApprovals("to")}>
              {l10n.getString("th-to")}
              <ArrowIcon column="to" />
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => sortApprovals("amount")}>
              {l10n.getString("th-amount")}
              <ArrowIcon column="amount" />
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => sortApprovals("submittedBy")}>
              {l10n.getString("th-submitted-by")}
              <ArrowIcon column="submittedBy" />
            </Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-type")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dualApprovalRequests.map(
            (dualApprovalRequest: DualApprovalRequest) => {
              const {
                uuid,
                created_at: createdAt,
                action,
                requester,
              } = dualApprovalRequest;

              return (
                <Table.Row key={`table-row__${uuid}`}>
                  <Table.Cell>
                    <DualApprovalCheckbox
                      isSelected={Boolean(selectedDualApprovals[uuid])}
                      handleToggleCheckbox={() =>
                        handleToggleCheckbox(dualApprovalRequest)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <span className="label mobile-only">
                      {l10n.getString("approval-label-date")}
                    </span>
                    {Filters.americanDate(createdAt.toISOString())}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="label mobile-only">
                      {l10n.getString("approval-table-to")}&nbsp;
                    </span>
                    {action.to}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="label mobile-only">
                      {l10n.getString("approval-table-amount")}&nbsp;
                    </span>
                    {Filters.currency(action.amount, { hasDecimal: true })}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="label mobile-only">
                      {l10n.getString("approval-table-submitted-by")}&nbsp;
                    </span>
                    {requester.getDescription()}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="label mobile-only">
                      {l10n.getString("approval-table-type")}&nbsp;
                    </span>
                    {action.type}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="fontSize--m narmi-icon-chevron-right" />
                  </Table.Cell>
                </Table.Row>
              );
            },
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default DualApprovalPendingTable;
