import { memo } from "react";
import AppAuthorize from "../../AppAuthorize";
import { RenderItemMenuProps } from "../types";

const AppItem = ({ item }: { item: RenderItemMenuProps<API.AppMenuItem> }) => (
  <AppAuthorize app={item.itemProps.app} key={item.itemProps.app.name}>
    {item.itemProps.app.name}
  </AppAuthorize>
);

export default memo(AppItem);
