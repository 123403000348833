import NavBar from "../NavBar";

import { TopMenuProps } from "./types";
import { isAccountItem, isAppItem, isLinkItem, isNafItem } from "./utils";
import { useTopMenuContainer } from "./topMenu.container";

import NAFItem from "./components/nafItem.component";
import LinkItem from "./components/linkItem.component";
import AccountItem from "./components/account.component";
import AppItem from "./components/appItem.component";
import NestedItem from "./components/nestedItem.component";

function TopMenuComponent(props: TopMenuProps) {
  const {
    menuItems,
    institution,
    features,
    orderedAccounts,
    legacyNafUrls,
    institutionLoading,
    accountsLoading,
  } = useTopMenuContainer(props);

  if (
    institutionLoading ||
    accountsLoading ||
    !institution ||
    Object.keys(institution).length === 0 || // Check for an empty object
    !features
  ) {
    return null;
  }

  return (
    <NavBar
      features={features}
      deviceIsRemembered={props.deviceIsRemembered}
      institution={institution}
    >
      {menuItems
        .map((item) => {
          if (isLinkItem(item.itemProps)) {
            return (
              <LinkItem
                item={{ name: item.name, itemProps: item.itemProps }}
                key={item.name}
              />
            );
          }

          if (isAccountItem(item.itemProps)) {
            return (
              <AccountItem
                item={{ name: item.name, itemProps: item.itemProps }}
                orderedAccounts={orderedAccounts}
                key={item.name}
              />
            );
          }

          if (isAppItem(item.itemProps)) {
            return (
              <AppItem
                item={{ name: item.name, itemProps: item.itemProps }}
                key={item.name}
              />
            );
          }

          if (isNafItem(item.itemProps)) {
            return (
              <NAFItem
                item={{ name: item.name, itemProps: item.itemProps }}
                legacyNafUrls={legacyNafUrls}
                key={item.name}
              />
            );
          }

          return null;
        })
        .filter(Boolean)}
      <NestedItem legacyNafUrls={legacyNafUrls} />
    </NavBar>
  );
}

export default TopMenuComponent;
