/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { modules } from "byzantine";
import { useCallback } from "react";

export const usePositivePay = () => {
  const { fetchHTML } = modules.positivePay.useFetchPositivePayHTML();

  const openHTML = useCallback(() => {
    fetchHTML({
      onData: ({ positive_pay_form }) => {
        const blob = new Blob([atob(positive_pay_form)], { type: "text/html" });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
      },
    });
  }, []);

  return openHTML;
};
