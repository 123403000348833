import { selectInstitution } from "../selectors";
import { createDeepEqualSelector } from "../utils";

export const selectNAFItems = createDeepEqualSelector(
  [selectInstitution],
  (institution) => {
    const listOfNafItems: {
      [key: string]: API.AppMenuItem | API.LinkMenuItem;
    } = {};

    institution?.apps?.forEach((app) => {
      listOfNafItems[app.name] = { app };
    });

    return listOfNafItems;
  },
);
