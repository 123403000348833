import { useFetchACHCompanies } from "../entities/achCompanies";
import { useFetchTemplates } from "../entities/fedwireTemplates/requests";
import { useFetchInstitution } from "../entities/institution/hooks";
import { useFetchUser } from "../entities/helpers/user.helpers";
import { useFetchRecipients } from "../entities/recipients/requests";
import { useFetchWireRecipients } from "../entities/wires";
import { useFetchAccounts } from "../entities/accounts";

import type { BootstrapRequestMap } from "./bootstrap.types";

export const requestHooks = {
  institution: useFetchInstitution,
  user: useFetchUser,
  fedwireTemplates: useFetchTemplates,
  wireRecipients: useFetchWireRecipients,
  recipients: useFetchRecipients,
  achCompanies: useFetchACHCompanies,
  accounts: useFetchAccounts,
} satisfies BootstrapRequestMap;

export type RequestHooks = keyof typeof requestHooks;
