/* eslint-disable no-unsafe-optional-chaining */
import {
  selectEndToEndTest,
  selectGAFeatures,
  selectInstitution,
  selectUser,
} from "../../entities";
import { createDeepEqualSelector } from "../../entities/utils/createDeepEqualSelector";
import { featureEquals } from "../../../../Feature";

import { isFeatureEnabled } from "./isEnabled";

import type { RootState } from "../../entities/store";
import type { FeatureQuery, FeatureValue } from "./types";
import type { RenderItemMenuProps } from "../../../../types";

export const selectFeatures = createDeepEqualSelector(
  [selectGAFeatures, selectInstitution, selectUser, selectEndToEndTest],
  (gaFeatures, institution, user, { featureOverrides }) => {
    // If we have user features (which we get right after the user authenticates,
    // Use them instead of the public institution features received before auth
    return {
      ...gaFeatures,
      ...(user?.features || institution?.features),
      ...featureOverrides,
    } as API.Features;
  },
);

export function selectFeatureEnabled(
  state: RootState,
  or?: FeatureQuery["or"],
  and?: FeatureQuery["and"],
  not?: FeatureQuery["not"],
) {
  const flags = selectFeatures(state);
  return isFeatureEnabled(flags, { or, and, not });
}

export function selectFeatureEquals(
  state: RootState,
  flag: string,
  setting?: FeatureValue | FeatureValue[],
) {
  const flags = selectFeatures(state);
  return featureEquals(flags, flag, setting);
}

export const selectTopMenu = createDeepEqualSelector(
  [selectFeatures, (state) => state],
  (features) => {
    const topMenu = features.top_menu;

    if (topMenu) {
      return Object.entries(topMenu)
        .map(([name, itemProps]) => {
          return { name, itemProps };
        })
        .filter(({ itemProps }) => {
          if ("feature_flag" in itemProps && itemProps.feature_flag) {
            return isFeatureEnabled(features, { or: itemProps.feature_flag });
          }
          return true;
        });
    }

    return [];
  },
);

type NestedMenuWithName = {
  nestedMenu?: RenderItemMenuProps<API.AnyMenuItem>[];
  menuName?: string;
};

export const selectNestedMenuWithName = createDeepEqualSelector(
  [selectFeatures, selectTopMenu],
  (features, topMenu): NestedMenuWithName => {
    // There should only be one nested menu in this setting
    const nestedMenuItem = topMenu.find((item) => {
      return "menu" in item.itemProps;
    });

    let extractedMenu: Record<string, API.AnyMenuItem>;
    let result;

    if (nestedMenuItem?.itemProps && "menu" in nestedMenuItem?.itemProps) {
      extractedMenu = nestedMenuItem.itemProps.menu;

      if (extractedMenu) {
        const formatted = Object.entries(extractedMenu).map(
          ([name, itemProps]) => {
            return { name, itemProps };
          },
        );

        result = formatted.filter(({ itemProps }) => {
          if ("feature_flag" in itemProps && itemProps.feature_flag) {
            return isFeatureEnabled(features, { or: itemProps.feature_flag });
          }
          return true;
        });
      }
    }
    return {
      nestedMenu: result,
      menuName: nestedMenuItem?.name,
    };
  },
);
