import { useState } from "react";
import { useLocalization } from "@fluent/react";
import DualApprovalRequest from "byzantine/src/DualApprovalRequest";
import Tabs from "../tabs/Tab";
import DualApprovalHistoryContainer from "./DualApprovalHistoryContainer";
import DualApprovalPendingContainer from "./DualApprovalPendingContainer";

type DualApprovalContainerType = {
  // TODO: update with ach type
  approvalRequests: API.WireDualApproval[];
};

export type SelectedDualApprovalsMapperType = {
  [key: string]: DualApprovalRequest;
};

const DualApprovalContainer = ({
  approvalRequests,
}: DualApprovalContainerType) => {
  const { l10n } = useLocalization();
  const [selectedDualApprovals, setSelectedDualApprovals] =
    useState<SelectedDualApprovalsMapperType>({});
  const [totalAwaitingApproval, setTotalAwaitingApproval] = useState(
    approvalRequests.length,
  );

  const handleSetTotalDualApprovals = (total: number) => {
    setTotalAwaitingApproval(total);
  };

  const renderPendingApprovals = () => (
    <DualApprovalPendingContainer
      selectedDualApprovals={selectedDualApprovals}
      setSelectedDualApprovals={setSelectedDualApprovals}
      updateTotal={handleSetTotalDualApprovals}
    />
  );

  const renderApprovalsHistory = () => <DualApprovalHistoryContainer />;

  const tabPanes = [
    {
      header: {
        text: l10n.getString("heading-pending-approvals-count", {
          totalAwaitingApproval,
        }),
      },
      render: renderPendingApprovals,
    },
    {
      header: l10n.getString("heading-approval-history"),
      render: renderApprovalsHistory,
    },
  ];

  return (
    <div className="DualApprovalRequestList">
      <Tabs panes={tabPanes} />
    </div>
  );
};

export default DualApprovalContainer;
