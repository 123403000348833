import { Table } from "semantic-ui-react";
import { useLocalization } from "@fluent/react";
import { Tag } from "@narmi/design_system";
import Filters from "byzantine/src/filters";
import DualApprovalRequest from "byzantine/src/DualApprovalRequest";
import styles from "./DualApproval.module.scss";

type DualApprovalHistoryTableType = {
  dualApprovalRequests: DualApprovalRequest[];
};

const DualApprovalHistoryTable = ({
  dualApprovalRequests,
}: DualApprovalHistoryTableType) => {
  const { l10n } = useLocalization();

  if (!dualApprovalRequests.length) {
    return (
      <div className="emptyState">
        {l10n.getString("heading-no-pending-approvals")}
      </div>
    );
  }

  return (
    <div>
      <Table selectable className={styles.table}>
        <Table.Header className="mobile-hidden">
          <Table.Row>
            <Table.HeaderCell>{l10n.getString("th-date")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-to")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-amount")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-account")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-status")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dualApprovalRequests.map(
            (dualApprovalRequest: DualApprovalRequest) => {
              const {
                uuid,
                created_at: createdAt,
                action,
              } = dualApprovalRequest;
              // TODO: check wire and ach states match this
              const tagKind =
                action.status === "rejected_approval" ||
                action.status === "canceled"
                  ? "error"
                  : "success";

              return (
                <Table.Row key={uuid}>
                  <Table.Cell>
                    <span className="label mobile-only">
                      {l10n.getString("approval-label-date")}
                    </span>
                    {Filters.americanDate(createdAt.toISOString())}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="label mobile-only">
                      {l10n.getString("approval-table-to")}&nbsp;
                    </span>
                    {action.to}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="label mobile-only">
                      {l10n.getString("approval-table-amount")}&nbsp;
                    </span>
                    {Filters.currency(action.amount, { hasDecimal: true })}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="label mobile-only">
                      {l10n.getString("approval-label-from")}
                    </span>
                    {action.from}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="label mobile-only">
                      {l10n.getString("approval-table-status")}&nbsp;
                    </span>
                    <Tag
                      kind={tagKind}
                      label={Filters.humanize(action.status)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <span className="fontSize--m narmi-icon-chevron-right" />
                  </Table.Cell>
                </Table.Row>
              );
            },
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default DualApprovalHistoryTable;
