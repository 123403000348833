import { Button } from "cerulean";

import { memo } from "react";
import { RenderItemMenuProps } from "../types";
import { getLabel } from "../utils";

const LinkItem = ({
  item,
}: {
  item: RenderItemMenuProps<API.LinkMenuItem>;
}) => {
  const label = getLabel(item);

  const { url, openNewTab } = item.itemProps;

  return (
    <Button
      as="a"
      label={label}
      kind="menu"
      href={!openNewTab ? url : undefined}
      onClick={openNewTab ? () => window.open(url, "_blank") : undefined}
      key={item.name}
    />
  );
};

export default memo(LinkItem);
