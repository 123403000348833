import { useState, useEffect } from "react";
import { useNotificationContext } from "cerulean";
import { useLocalization } from "@fluent/react";
import Pagination from "byzantine/src/Pagination";
import Loading from "../Loading";
import PageNavigation from "../page_navigation/PageNavigation";
import DualApprovalHistoryTable from "./DualApprovalHistoryTable";
import { usePaginatedApprovals } from "./DualApprovalPendingContainer";

const PAGE_SIZE = 10;

const DualApprovalHistoryContainer = () => {
  const { l10n } = useLocalization();
  const [page, setPage] = useState(1);
  const { sendNotification } = useNotificationContext();

  const { fetchPaginatedDualApprovals, dualApprovals, total, loading } =
    usePaginatedApprovals(
      "responded_approval_requests",
      "approval_requests",
      page,
      PAGE_SIZE,
    );

  // wrapper to show error notification
  const handleFetchPaginatedApprovals = () => {
    fetchPaginatedDualApprovals().then((result) => {
      if (!result.success) {
        sendNotification({
          type: "negative",
          text: l10n.getString("error-generic"),
        });
      }
    });
  };

  useEffect(() => {
    handleFetchPaginatedApprovals();
  }, [page]);

  if (loading) {
    return <Loading />;
  }

  if (dualApprovals.length === 0) {
    return (
      <div className="empty-message">
        {l10n.getString("heading-no-approval-history")}
      </div>
    );
  }

  const pagination = new Pagination({
    total,
    page,
    pageSize: PAGE_SIZE,
    navigatePage: setPage,
  });

  return (
    <>
      <DualApprovalHistoryTable dualApprovalRequests={dualApprovals} />
      <PageNavigation pagination={pagination} marginClasses="margin--all--l" />
    </>
  );
};

export default DualApprovalHistoryContainer;
