import React from "react";
import { useLocalization } from "@fluent/react";
import Organization from "byzantine/src/Organization";
import OrganizationUser from "byzantine/src/OrganizationUser";
import { NotificationContext } from "cerulean";

const DualApprovalNotificationBar = () => {
  const { l10n } = useLocalization();
  /* sends an info toast about outstanding dual approvals if they exist */
  const { sendNotification } = React.useContext(NotificationContext);
  const [numRequests, setNumRequests] = React.useState(0);

  React.useEffect(() => {
    const orgUser = OrganizationUser.currentOrgUserFromHTML();
    if (!orgUser?.uuid) return;
    Organization.getNumberOfDualApprovalRequests()
      .then((response) => setNumRequests(response))
      .catch(() => setNumRequests(0));
  }, []);

  React.useEffect(() => {
    if (numRequests) {
      sendNotification({
        type: "info",
        text: l10n.getString("approval-notif-num-pending-markdown", {
          numRequests,
        }),
      });
    }
  }, [numRequests]);

  return null;
};

export default DualApprovalNotificationBar;
