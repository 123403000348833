/* eslint-disable import/prefer-default-export */
import { Button } from "cerulean";
import { memo } from "react";
import { usePositivePay } from "./container";

const PositivePayButton = () => {
  const openHTML = usePositivePay();

  return (
    <Button
      as="a"
      label="Positive Pay"
      kind="menu"
      onClick={openHTML}
      key={"positive_pay"}
    />
  );
};

export default memo(PositivePayButton);
