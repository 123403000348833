import { Button } from "cerulean";
import utils from "byzantine/src/utils";
import { useFeature, useNestedMenu } from "byzantine";
import { memo } from "react";
import type { RenderItemMenuProps } from "byzantine/src/types";

import Details from "../../Details";
import { isAppItem, isLinkItem, isNafItem } from "../utils";
import LinkItem from "./linkItem.component";
import AppItem from "./appItem.component";
import NAFItem from "./nafItem.component";
import PositivePayButton from "../../positivePay/button.component";

type Props = {
  legacyNafUrls: any;
};

const NestedItem = ({ legacyNafUrls }: Props) => {
  const { nestedMenu, menuName } = useNestedMenu();
  const { featureEnabled } = useFeature();

  if (!nestedMenu) {
    return null;
  }

  const renderedItems = nestedMenu
    .map((item: RenderItemMenuProps<API.AnyMenuItem>) => {
      if (isLinkItem(item.itemProps)) {
        return (
          <LinkItem
            item={{ name: item.name, itemProps: item.itemProps }}
            key={item.name}
          />
        );
      }

      // This is a NAF app
      if (isAppItem(item.itemProps)) {
        return (
          <AppItem
            item={{ name: item.name, itemProps: item.itemProps }}
            key={item.name}
          />
        );
      }

      if (isNafItem(item.itemProps)) {
        return (
          <NAFItem
            item={{ name: item.name, itemProps: item.itemProps }}
            legacyNafUrls={legacyNafUrls}
            key={item.name}
          />
        );
      }

      return null;
    })
    .filter(Boolean);

  const unpackedItems: React.ReactElement[] = renderedItems.flatMap((item) => {
    if (item && item.key !== "naf") {
      return item;
    }
    return item?.props.children;
  });

  if (featureEnabled("positive_pay")) {
    unpackedItems.push(<PositivePayButton key="positivePay" />);
  }

  const chunkSize = unpackedItems.length % 5 !== 1 ? 5 : 6;

  const chunkedItems = utils.shardArrayByChunkSize(unpackedItems, chunkSize);

  return (
    <Details
      summary={<Button as="a" kind="menu" label={menuName} />}
      key={menuName}
      type="wide details"
    >
      {chunkedItems.map((menuChunk, index) => (
        <div key={index} className="tools">
          {menuChunk}
        </div>
      ))}
    </Details>
  );
};

export default memo(NestedItem);
