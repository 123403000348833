// @ts-ignore
import React from "react";
// we seem to have trouble loading types from classcat
// @ts-ignore
import cc from "classcat";
import { Button } from "@narmi/design_system";

const BACK_LABEL = "Back";

interface BackCancelButtonProps {
  onBack: (event: any) => void;
  backLabel: string;
  disabled: any;
}
const BackCancelButton = ({
  onBack,
  backLabel = BACK_LABEL,
  disabled,
}: BackCancelButtonProps): JSX.Element => (
  <span className="back-cancel-button">
    {!disabled && (
      <button
        style={{
          color: "rgba(var(--nds-grey))",
          paddingLeft: 0,
          border: "none",
          backgroundColor: "transparent",
          fontWeight: "normal",
        }}
        type="button"
        className={cc([
          "nds-plain-button",
          {
            "negative-margin": backLabel === BACK_LABEL,
          },
        ])}
        onClick={onBack}
      >
        {backLabel === BACK_LABEL && (
          <>
            {" "}
            <span className="narmi-icon-chevron-left" style={{ verticalAlign: "middle" }} />{" "}
          </>
        )}
        <span
          className={cc([
            "back-button-text hoverable",
            {
              "negative-margin": backLabel === BACK_LABEL,
            },
          ])}
        >
          {backLabel}{" "}
        </span>{" "}
      </button>
    )}
    {disabled && (
      <Button
        kind="negative"
        startIcon={backLabel === BACK_LABEL ? "chevron-left" : undefined}
        label={backLabel}
        testId="back"
        type="button"
        disabled
      />
    )}
  </span>
);

export default BackCancelButton;
