import User from "./User";
import Filters from "./filters";
import ApiHttp from "./ApiHttp";

export type DualApprovalRequestActionProps = {
  type: string;
  from: API.Wire["from_account_display"];
  to: API.Wire["beneficiary_name"];
  amount: Dollars;
  status: API.Wire["state"];
};

export type DualApprovalRequestProps = {
  uuid: string;
  created_at: Date;
  requester: User;
  responder: User | null;
  action: DualApprovalRequestActionProps;
};

class DualApprovalRequestAction {
  type!: DualApprovalRequestActionProps["type"];

  from!: DualApprovalRequestActionProps["from"];

  to!: DualApprovalRequestActionProps["to"];

  amount!: DualApprovalRequestActionProps["amount"];

  status!: DualApprovalRequestActionProps["status"];

  constructor(props: API.WireDualApproval) {
    if (props.wire) {
      this.type = "Wire transfer";
      this.from = props.wire.from_account_display;
      this.to = props.wire.beneficiary_name;
      this.amount = Filters.penniesToDollars(props.wire.amount);
      this.status = props.wire.state;
    }
  }
}

export default class DualApprovalRequest {
  public uuid: DualApprovalRequestProps["uuid"];

  public created_at: DualApprovalRequestProps["created_at"];

  public requester: DualApprovalRequestProps["requester"];

  public responder: DualApprovalRequestProps["responder"];

  public action: DualApprovalRequestProps["action"];

  constructor(props: DualApprovalRequestProps) {
    this.uuid = props.uuid;
    this.created_at = props.created_at;
    this.requester = props.requester;
    this.responder = props.responder;
    this.action = props.action;
  }

  approve() {
    const payload = { action: "approve" };
    return ApiHttp.fetch(
      `approval_requests/${this.uuid}`,
      { method: "POST" },
      payload,
    ).then((response) => {
      this.action.status = response.approval_request.wire.state;
      return response;
    });
  }

  reject() {
    const payload = { action: "reject" };
    return ApiHttp.fetch(
      `approval_requests/${this.uuid}`,
      { method: "POST" },
      payload,
    ).then((response) => {
      this.action.status = response.approval_request.wire.state;
      return response;
    });
  }

  static deserialize(payload: API.WireDualApproval) {
    return new DualApprovalRequest({
      uuid: payload.uuid,
      created_at: new Date(payload.created_at),
      requester: User.deserialize(payload.requester),
      responder: payload.responder ? User.deserialize(payload.responder) : null,
      action: new DualApprovalRequestAction(payload),
    });
  }
}
