import type { NetworkPayload } from "../../../composites";

export const API = {
  fetchPositivePay: (): NetworkPayload => {
    return {
      url: "positive_pay",
      options: {
        method: "GET",
      },
    };
  },
};
